import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import HeadHelmet from '../../components/head';
import { StyledBackground } from '../../components/ui-elements/backgroundImage';

import GoogleMap from '../../components/googleMap';

const ContactPage = ({ data }) => {
  const { strapiSsContactPage } = data;
  return (
    <Layout>
      <HeadHelmet data={strapiSsContactPage.MetaData} />
      <StyledBackground
        img={strapiSsContactPage.hero_image_contact_page.childImageSharp.fluid}
        title={strapiSsContactPage.title}
        titleWidth="100%"
      />
      <div className={'Contact__desktopContentGrid'}>
        <div>
          <div className={'SharedEl__textContent--centerNormalMargin SharedEl__container--greyBG'}>
            <div className={'Contact__mainInfoDiv'}>
              <h2>{strapiSsContactPage.info_form_title}</h2>
              <div className={'Contact__helperDivs'}>
                <i className="fas fa-envelope-open-text" />
                <a href="mailto:info@stanfordsonoma.com">{strapiSsContactPage.info_form_email}</a>
                <p>email</p>
              </div>
              <div className={'Contact__helperDivs'}>
                <i className="fas fa-phone" />
                <a href="tel:+4699300340">{strapiSsContactPage.info_form_phone}</a>
                <p>phone</p>
              </div>
              <div className={'Contact__helperDivs'}>
                <i className="fas fa-map-marker-alt" />
                <a
                  href="https://www.google.com/maps/place/12750+Perimeter+Dr+%23154,+Dallas,+TX+75228,+Sjedinjene+Dr%C5%BEave/@32.8601574,-96.6621586,17z/data=!3m1!4b1!4m5!3m4!1s0x864ea72bc9e7533f:0x762a26c941cbfaf1!8m2!3d32.8601574!4d-96.6599699"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {strapiSsContactPage.info_form_address}
                </a>
                <p>address</p>
              </div>
            </div>
          </div>
        </div>
        <div className={'SharedEl__textContent--leftNormalMargin'}>
          <div className={'Contact__desktopFormsGrid'}>
            <form
              className={'Contact__form'}
              method="POST"
              name="contact"
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input
                type="hidden"
                name="subject"
                value="New form submission from stanfordsonoma.com"
              />
              <label>
                First Name*
                <input type="text" name="firstname" required />
              </label>{' '}
              <br />
              <label>
                Last Name*
                <input type="text" name="lastname" required />
              </label>{' '}
              <br />
              <label>
                Company Name
                <input type="text" name="companyname" />
              </label>{' '}
              <br />
              <label>
                Email*
                <input type="email" name="email" required />
              </label>{' '}
              <br />
              <label>
                Phone Number*
                <input type="number" name="phonenumber" />
              </label>{' '}
              <br />
              <label>
                Message
                <textarea name="message" />
              </label>{' '}
              <br />
              <button className={'Services__blueButton'} type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className={'Contact__mapDiv'} style={{ width: '100%', height: '400px' }}>
        <GoogleMap />
      </div>
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    strapiSsContactPage {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      hero_image_contact_page {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      info_form_title
      info_form_phone
      info_form_email
      info_form_address
    }
  }
`;
