import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

const style = {
  width: '100%',
  height: '400px'
};

export class MapContainer extends Component {

  render() {
    return (
      <Map google={this.props.google}
           zoom={14}
           style={style}
           initialCenter={{
             lat: 32.860191,
             lng: -96.659910
           }}
      >

        <Marker onClick={this.onMarkerClick}
                name={'Current location'}
                title={'12750 Perimeter Drive'}

        />

      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAKm-Ub0ySKVq-u3EWUhqAmaOBCJosi1oA')
})(MapContainer)
